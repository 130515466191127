import React from 'react'
import Layout from '../components/layout'

export default function About () {
  return (
    <Layout>
      <section className='story'>
        <div className='row align-center'>
          <div className='col'>
            <div className="inner">
              <h1 className='bar'>Story time</h1>
              <p>From idea to product, we are continuing to write our history.</p>
              <figure style={{ backgroundImage: 'url(\'/img/about-ottawa.jpg\')' }}></figure>
              <h2>Trexity origins</h2>
              <p>We remember those days, we remember them well. It wasn’t too long ago that one of our co-founders was stuck in a logistical predicament. Trying to get an item from Ottawa to Montreal within the same day and returned to them later that day seemed to be a possibility. After trying to contact several shipping providers and couriers, nobody was able to help and only offered to ship the next day and at a premium inflated pricing structure. This was not going to be a viable option and there was no way we were going to let the shipping companies continue to dictate timelines and price gouge us.</p>

              <figure style={{ backgroundImage: 'url(\'/img/about-people.jpg\')' }}></figure>
              <h2>Talking with the people</h2>
              <p>The Trexity team was slowly starting to form around trying to solve for city-to-city deliveries and how we could leverage drivers who were already going in those directions. As we started mapping out architecture and development, we started to take note of some of the business side requirements from our feedback groups. Merchants were more concerned with trying to solve major issues around home delivery and daily orders so they could compete or even be on the same playing field as some of the big box worldwide retailers. Trexity would be the answer.</p>

              <figure style={{ backgroundImage: 'url(\'/img/about-pivot.jpg\')' }}></figure>
              <h2>The Pivot</h2>
              <p>Within hours, the Trexity team knew a pivot was needed for us to help merchants solve for home delivery and work to create a national driver community that could help scale our service offering and platform. We are continuing to write our history, and take pride in knowing that we are helping merchants across Canada achieve a unique level of decentralized shipping. All while working to build a world class driver community is history by helping merchants grow each day.</p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
